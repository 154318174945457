@justifyOptions: center, flex-end, flex-start, space-between, space-around,
  space-evenly;

.justify(@align) {
  display: flex;
  justify-content: @align;
  align-items: center;
}

each(@justifyOptions, {
      .flex-center-@{value} {
          .justify(@value)
      } 
  });

  .heading()
  {
  position: relative;
	font-weight: normal;
	margin: 0px;
	background: none;
	line-height: 1.2em;
	font-family: @font_family_nunito;
  }
  .flex-ac-sb()
  {
    display: flex;
		align-items: center;
		justify-content: space-between;
  }
  .flex-ac()
  {
    display: flex;
		align-items: center;
  }
  .flex-sb()
  {
    display: flex;
		justify-content: space-between;
  }
  .flex-sa()
  {
    display: flex;		
    justify-content: space-around;
  }
  .tarnsition-200()
  {
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
  }
  .tarnsition-300()
  {
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
  }
  .tarnsition-500()
  {
	-webkit-transition: all 500ms ease-in-out;
		-moz-transition: all 500ms ease-in-out;
		-ms-transition: all 500ms ease-in-out;
		-o-transition: all 500ms ease-in-out;
		transition: all 500ms ease-in-out;
  }
  .tarnsition-3sl()
  {
	-webkit-transition: all 0.3s linear;
	-moz-transition: all 0.3s linear;
	-ms-transition: all 0.3s linear;
	-o-transition: all 0.3s linear;
	transition: all 0.3s linear;
  }
.tarnsition-3s()
  {
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
  }
  .tarnsition-5s()
  {
	-webkit-transition: all 0.5s ease-in-out;
	-moz-transition: all 0.5s ease-in-out;
	-ms-transition: all 0.5s ease-in-out;
	-o-transition: all 0.5s ease-in-out;
	transition: all 0.5s ease-in-out;
  }
   .tarnsition-7s()
  {
	-webkit-transition: all 0.7s ease-in-out;
	-moz-transition: all 0.7s ease-in-out;
	-ms-transition: all 0.7s ease-in-out;
	-o-transition: all 0.7s ease-in-out;
	transition: all 0.7s ease-in-out;
  }
  
  .box-shadow-orange()
  {
	-webkit-box-shadow: rgba(255, 156, 0, 1) 0 0px 0px 40px inset;
	-moz-box-shadow: rgba(255, 156, 0, 1) 0 0px 0px 40px inset;
	-ms-box-shadow: rgba(255, 156, 0, 1) 0 0px 0px 40px inset;
	-o-box-shadow: rgba(255, 156, 0, 1) 0 0px 0px 40px inset;
	box-shadow: rgba(255, 156, 0, 1) 0 0px 0px 40px inset;
  }
  .box-shadow-blue()
  {
  -webkit-box-shadow: rgba(33, 84, 207, 1) 0 0px 0px 40px inset;
		-moz-box-shadow: rgba(33, 84, 207, 1) 0 0px 0px 40px inset;
		-ms-box-shadow: rgba(33, 84, 207, 1) 0 0px 0px 40px inset;
		-o-box-shadow: rgba(33, 84, 207, 1) 0 0px 0px 40px inset;
		box-shadow: rgba(33, 84, 207, 1) 0 0px 0px 40px inset;
  }
  .box-shadow-white()
  {
  -webkit-box-shadow: rgba(255, 255, 255, 0) 0 0px 0px 0px inset;
	-moz-box-shadow: rgba(255, 255, 255, 0) 0 0px 0px 0px inset;
	-ms-box-shadow: rgba(255, 255, 255, 0) 0 0px 0px 0px inset;
	-o-box-shadow: rgba(255, 255, 255, 0) 0 0px 0px 0px inset;
	box-shadow: rgba(255, 255, 255, 0) 0 0px 0px 0px inset;
	}