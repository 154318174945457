@Blue: rgb(33,84,207);
@Orange: rgb(255,156,0);

@White: #ffffff;
@Black: #000000;
@Black_2: #222222;
@Grey_d: #dddddd;
@Grey_e: #ebebeb;
@Grey_7: #777777;
@Grey: #666666;

@Inherit: inherit;

@font_family_roboto: "Roboto", sans-serif;
@font_family_nunito: "Nunito Sans", sans-serif;
@font_family_awesome: "Font Awesome 5 Free";

@background_color_1: #f5f5f5;
@background_color_7: #f5f8fa;
@background_color_8: rgba(0, 0, 0, 0.6);

@border_color_1: rgba(255, 156, 0, 0);
@border_color_2: rgba(33, 84, 207, 1);

@font-12: 12px;
@font-14: 14px;
@font-16: 16px;
@font-18: 18px;
@font-20: 20px;
@font-22: 22px;
@font-24: 24px;
@font-26: 26px;
@font-28: 28px;
@font-30: 30px;
@font-32: 32px;
@font-34: 34px;
@font-36: 36px;
@font-38: 38px;

@none: none;
